<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-2">ဆိုင်ခွဲ နေ့စဥ်စာရင်း</div>
                            <div class="col-md-2">
                                <input type="month" v-model="month" class="form-control" @blur="fetchData()">
                            </div>
                            <div class="col-md-2">
                            <select v-if="this.$role == 'admin'" id="inputItemId" @change="fetchData()" class="form-control" v-model="shop">
                                <option v-for="shop in shopList" :value="shop.id">{{shop.name}}</option>
                            </select>
                            </div>
                            <!-- <div class="col-md-1 mt-2">
                                <input type="radio" value="No"  v-model="noType"  @change="fetchData()"> ဆိုင်ခွဲ မရှိ
                            </div>
                             <div class="col-md-1 mt-2" >
                                <input type="radio" value="A" v-model="noType"  @change="fetchData()"> ဆိုင်ခွဲ A
                            </div>
                             <div class="col-md-1 mt-2" >
                                <input type="radio" value="B" v-model="noType"  @change="fetchData()"> ဆိုင်ခွဲ B
                            </div> -->
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-bordered" style="width: 1800px">
                                        <thead>
                                            <tr>
                                                <th class="th-width-100">ရက်စွဲ</th>
                                                <th class="th-width-100">အပေါင်ခုရေ</th>
                                                <th class="th-width-100">ချေးငွေ</th>
                                                <th class="th-width-100">အရွေးခုရေ</th>
                                                <th class="th-width-100">ချေးငွေအရင်း</th>
                                                <th class="th-width-100">အတိုး</th>
                                                <th class="th-width-100">တိုးရင်းပေါင်း</th>
                                                <th class="th-width-100">ပိုငွေ / စိုက်ငွေ</th>
                                                <th class="th-width-100">တွက်ချက်ပြီး</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="bg-primary">
                                                <td></td>
                                                <td>{{metaList.totalMortgageCount}}</td>
                                                <td>{{metaList.totalLoan}}</td>
                                                <td>{{metaList.payCount}}</td>
                                                <td>{{metaList.payLoan}}</td>
                                                <td>{{metaList.interest}}</td>
                                                <td>{{metaList.loanInterest}}</td>
                                                <td></td>
                                                <td>{{metaList.gold}}</td>
                                                <td></td>
                                            </tr>
                                            <tr v-for="(data,index) in dataList" v-if="data">
                                                <td>{{data.date}}</td>
                                                <td>{{data.mortgageQunatity}}</td>
                                                <td>{{data.mortgageLoan}}</td>
                                                <td>{{data.payQuantity}}</td>
                                                <td>{{data.payLoan}}</td>
                                                <td>{{data.payInterest}}</td>
                                                <td>{{data.payLoanInterest}}</td>
                                                <td v-bind:class="{'text-danger': data.goldSign === 'm'}">{{data.gold}}</td>
                                                <td v-bind:class="{'text-danger': data.goldSignCalc === 'm'}">{{data.goldCalc}}</td>
                                                <td>
                                                    <button class="btn btn-success text-white" @click="saveData(data)">
                                                        လုပ်ဆောင်မည်
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr v-if="dataList.length <= 0">
                                                <td colspan="11" class="text-center">Empty List</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" title="သတိပေးချက်" hide-footer>
            <p class="my-4">ရွေးလို့မရပါ။</p>
        </b-modal>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import moment from 'moment';
    export default {
        name: 'shopReportList',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                alert: "",
                id: '',
                month: '',
                shop: '',
                noType: "No",
                shopList: '',
                dataList: [],
                metaList: []
            }
        },
        watch: {
            '$route' (to, from) {
                this.fetchData()
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                dailyReportListForClothAction: 'dailyReportListForClothAction',
                dailyReportSaveForClothAction: 'dailyReportSaveForClothAction',
                mortgageFieldsAction: 'mortgageFieldsAction',
            }),
            async getFields() {
                let option = {
                    fields: {
                        type: "field"
                    }
                }
                await this.mortgageFieldsAction({
                    ...option
                }).then(res => {
                    this.shopList = res.data.data.shop
                    this.shop = res.data.data.shop[0].id
                    this.fetchData()
                }).catch(err => this.isLoading = true)
            },
            async fetchData() {
                this.isLoading = true
                let current = moment(new Date()).format('YYYY-MM')
                if(this.month > current){
                    this.isLoading = false;
                    this.$refs['my-modal'].show()
                    return
                }
                let option = {
                    type: "shop",
                    month: this.month,
                    shop: this.shop,
                    noType: this.noType
                }
                await this.dailyReportListForClothAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.dataList = res.data.data.data
                        this.metaList = res.data.data.meta
                        this.$router.replace({ path: 'shop-daily-report-list-for-cloth', query: { month: this.month, shop: this.shop} }).catch(()=>{})
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            async saveData(data) {
                this.isLoading = true
                let option = {
                    saveData: data,
                    shopId: this.shop,
                    noType: this.noType
                }
                await this.dailyReportSaveForClothAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.fetchData()
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            }

        },
        mounted() {
            this.month = this.$route.query.month
            this.shop = this.$route.query.shop
            this.getFields()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>